/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SiteFilterOption_site = {
    readonly name: string;
    readonly id: string;
    readonly " $refType": "SiteFilterOption_site";
};
export type SiteFilterOption_site$data = SiteFilterOption_site;
export type SiteFilterOption_site$key = {
    readonly " $data"?: SiteFilterOption_site$data;
    readonly " $fragmentRefs": FragmentRefs<"SiteFilterOption_site">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteFilterOption_site",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "Site",
    "abstractKey": null
} as any);
(node as any).hash = '4bcc98f201e58068004241673c8bf390';
export default node;
