/* tslint:disable */
/* eslint-disable */
/* @relayHash a33c1c7d03edd191d95a21ab2660a66c */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WordListConfigRouteQueryVariables = {};
export type WordListConfigRouteQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"WordListConfigContainer_settings">;
    };
};
export type WordListConfigRouteQuery = {
    readonly response: WordListConfigRouteQueryResponse;
    readonly variables: WordListConfigRouteQueryVariables;
};



/*
query WordListConfigRouteQuery {
  settings {
    ...WordListConfigContainer_settings
    id
  }
}

fragment WordListConfigContainer_settings on Settings {
  wordList {
    suspect
    banned
  }
}
*/

const node: ConcreteRequest = ({
    "fragment": {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "WordListConfigRouteQuery",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Settings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "WordListConfigContainer_settings"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    },
    "kind": "Request",
    "operation": {
        "argumentDefinitions": [],
        "kind": "Operation",
        "name": "WordListConfigRouteQuery",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Settings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "WordList",
                        "kind": "LinkedField",
                        "name": "wordList",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "suspect",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "banned",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ]
    },
    "params": {
        "id": "a33c1c7d03edd191d95a21ab2660a66c",
        "metadata": {},
        "name": "WordListConfigRouteQuery",
        "operationKind": "query",
        "text": null
    }
} as any);
(node as any).hash = 'e1f093a1dde24d4d01851b1cc7d70d6c';
export default node;
