/* tslint:disable */
/* eslint-disable */
/* @relayHash f9b1eafec5e0544791ebde97d5cb6564 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SectionFilter = {
    name?: string | null;
};
export type QueueRoutePaginationUnmoderatedQueryVariables = {
    storyID?: string | null;
    siteID?: string | null;
    section?: SectionFilter | null;
    count: number;
    cursor?: unknown | null;
};
export type QueueRoutePaginationUnmoderatedQueryResponse = {
    readonly moderationQueues: {
        readonly unmoderated: {
            readonly " $fragmentRefs": FragmentRefs<"QueueRoute_queue">;
        };
    };
};
export type QueueRoutePaginationUnmoderatedQuery = {
    readonly response: QueueRoutePaginationUnmoderatedQueryResponse;
    readonly variables: QueueRoutePaginationUnmoderatedQueryVariables;
};



/*
query QueueRoutePaginationUnmoderatedQuery(
  $storyID: ID
  $siteID: ID
  $section: SectionFilter
  $count: Int!
  $cursor: Cursor
) {
  moderationQueues(storyID: $storyID, siteID: $siteID, section: $section) {
    unmoderated {
      ...QueueRoute_queue_1G22uz
      id
    }
  }
}

fragment CommentAuthorContainer_comment on Comment {
  author {
    id
    username
    status {
      suspension {
        active
      }
      premod {
        active
      }
      ban {
        active
      }
    }
  }
}

fragment CommentRevisionContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    id
  }
  revisionHistory {
    id
    body
    createdAt
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
  }
}

fragment FlagDetailsContainer_comment on Comment {
  flags {
    nodes {
      flagger {
        username
        id
      }
      reason
      additionalDetails
    }
  }
  revision {
    metadata {
      perspective {
        score
      }
    }
    id
  }
}

fragment LinkDetailsContainer_comment on Comment {
  id
  story {
    id
    url
  }
}

fragment MarkersContainer_comment on Comment {
  ...ModerateCardDetailsContainer_comment
  status
  revision {
    actionCounts {
      flag {
        reasons {
          COMMENT_REPORTED_SPAM
          COMMENT_REPORTED_OTHER
          COMMENT_REPORTED_OFFENSIVE
          COMMENT_REPORTED_ABUSIVE
          COMMENT_REPORTED_BIO
          COMMENT_DETECTED_TOXIC
          COMMENT_DETECTED_SUSPECT_WORD
          COMMENT_DETECTED_SPAM
          COMMENT_DETECTED_REPEAT_POST
          COMMENT_DETECTED_RECENT_HISTORY
          COMMENT_DETECTED_NEW_COMMENTER
          COMMENT_DETECTED_LINKS
          COMMENT_DETECTED_BANNED_WORD
        }
      }
    }
    metadata {
      wordList {
        timedOut
      }
    }
    id
  }
}

fragment MediaContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
    id
  }
}

fragment ModerateCardContainer_comment on Comment {
  id
  author {
    id
    username
    status {
      current
    }
  }
  createdAt
  body
  rating
  revision {
    actionCounts {
      flag {
        reasons {
          COMMENT_DETECTED_BANNED_WORD
          COMMENT_DETECTED_SUSPECT_WORD
        }
      }
    }
    id
  }
  tags {
    code
  }
  status
  editing {
    edited
  }
  parent {
    author {
      id
      username
    }
    id
  }
  canModerate
  story {
    id
    metadata {
      title
    }
    settings {
      mode
    }
  }
  site {
    id
    name
  }
  permalink
  deleted
  ...MarkersContainer_comment
  ...ModeratedByContainer_comment
  ...CommentAuthorContainer_comment
  ...MediaContainer_comment
}

fragment ModerateCardDetailsContainer_comment on Comment {
  editing {
    edited
  }
  revision {
    actionCounts {
      flag {
        reasons {
          COMMENT_REPORTED_OFFENSIVE
          COMMENT_REPORTED_ABUSIVE
          COMMENT_REPORTED_SPAM
          COMMENT_REPORTED_OTHER
        }
      }
    }
    metadata {
      perspective {
        score
      }
    }
    id
  }
  ...FlagDetailsContainer_comment
  ...CommentRevisionContainer_comment
  ...LinkDetailsContainer_comment
}

fragment ModeratedByContainer_comment on Comment {
  id
  status
  statusHistory(first: 1) {
    edges {
      node {
        status
        moderator {
          id
          username
        }
        id
      }
    }
  }
}

fragment QueueRoute_queue_1G22uz on ModerationQueue {
  count
  comments(first: $count, after: $cursor) {
    edges {
      node {
        id
        ...ModerateCardContainer_comment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "count"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "cursor"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "section"
    } as any), v3 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "siteID"
    } as any), v4 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "storyID"
    } as any), v5 = [
        ({
            "kind": "Variable",
            "name": "section",
            "variableName": "section"
        } as any),
        ({
            "kind": "Variable",
            "name": "siteID",
            "variableName": "siteID"
        } as any),
        ({
            "kind": "Variable",
            "name": "storyID",
            "variableName": "storyID"
        } as any)
    ], v6 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any)
    ], v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v9 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
        } as any)
    ], v10 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
            (v7 /*: any*/),
            (v8 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "UserStatus",
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "current",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SuspensionStatus",
                        "kind": "LinkedField",
                        "name": "suspension",
                        "plural": false,
                        "selections": (v9 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PremodStatus",
                        "kind": "LinkedField",
                        "name": "premod",
                        "plural": false,
                        "selections": (v9 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "BanStatus",
                        "kind": "LinkedField",
                        "name": "ban",
                        "plural": false,
                        "selections": (v9 /*: any*/),
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v11 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any), v12 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
    } as any), v13 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rating",
        "storageKey": null
    } as any), v14 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any), v15 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v16 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any), v17 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any), v18 = [
        (v15 /*: any*/)
    ], v19 = ({
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "media",
        "plural": false,
        "selections": [
            (v14 /*: any*/),
            {
                "kind": "InlineFragment",
                "selections": [
                    (v15 /*: any*/),
                    (v16 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "width",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "height",
                        "storageKey": null
                    },
                    (v17 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "video",
                        "storageKey": null
                    }
                ],
                "type": "GiphyMedia",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": (v18 /*: any*/),
                "type": "TwitterMedia",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v15 /*: any*/),
                    (v17 /*: any*/),
                    (v16 /*: any*/)
                ],
                "type": "YouTubeMedia",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": (v18 /*: any*/),
                "type": "ExternalMedia",
                "abstractKey": null
            }
        ],
        "storageKey": null
    } as any), v20 = ({
        "alias": null,
        "args": null,
        "concreteType": "CommentRevision",
        "kind": "LinkedField",
        "name": "revision",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ActionCounts",
                "kind": "LinkedField",
                "name": "actionCounts",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "FlagActionCounts",
                        "kind": "LinkedField",
                        "name": "flag",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "FlagReasonActionCounts",
                                "kind": "LinkedField",
                                "name": "reasons",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_BANNED_WORD",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_SUSPECT_WORD",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_REPORTED_OFFENSIVE",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_REPORTED_ABUSIVE",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_REPORTED_SPAM",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_REPORTED_OTHER",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_REPORTED_BIO",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_TOXIC",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_SPAM",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_REPEAT_POST",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_RECENT_HISTORY",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_NEW_COMMENTER",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "COMMENT_DETECTED_LINKS",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            (v7 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "CommentRevisionMetadata",
                "kind": "LinkedField",
                "name": "metadata",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentRevisionPerspectiveMetadata",
                        "kind": "LinkedField",
                        "name": "perspective",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "score",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentRevisionWordListMetadata",
                        "kind": "LinkedField",
                        "name": "wordList",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "timedOut",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            (v19 /*: any*/)
        ],
        "storageKey": null
    } as any), v21 = ({
        "alias": null,
        "args": null,
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "tags",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v22 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any), v23 = ({
        "alias": null,
        "args": null,
        "concreteType": "EditInfo",
        "kind": "LinkedField",
        "name": "editing",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "edited",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v24 = [
        (v7 /*: any*/),
        (v8 /*: any*/)
    ], v25 = ({
        "alias": null,
        "args": null,
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "parent",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": (v24 /*: any*/),
                "storageKey": null
            },
            (v7 /*: any*/)
        ],
        "storageKey": null
    } as any), v26 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "canModerate",
        "storageKey": null
    } as any), v27 = ({
        "alias": null,
        "args": null,
        "concreteType": "Story",
        "kind": "LinkedField",
        "name": "story",
        "plural": false,
        "selections": [
            (v7 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "StoryMetadata",
                "kind": "LinkedField",
                "name": "metadata",
                "plural": false,
                "selections": [
                    (v16 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "StorySettings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mode",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            (v15 /*: any*/)
        ],
        "storageKey": null
    } as any), v28 = ({
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
            (v7 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v29 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permalink",
        "storageKey": null
    } as any), v30 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
    } as any), v31 = ({
        "alias": null,
        "args": null,
        "concreteType": "FlagsConnection",
        "kind": "LinkedField",
        "name": "flags",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Flag",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "flagger",
                        "plural": false,
                        "selections": [
                            (v8 /*: any*/),
                            (v7 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reason",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "additionalDetails",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v32 = ({
        "alias": null,
        "args": null,
        "concreteType": "CommentRevision",
        "kind": "LinkedField",
        "name": "revisionHistory",
        "plural": true,
        "selections": [
            (v7 /*: any*/),
            (v12 /*: any*/),
            (v11 /*: any*/),
            (v19 /*: any*/)
        ],
        "storageKey": null
    } as any), v33 = ({
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "first",
                "value": 1
            }
        ],
        "concreteType": "CommentModerationActionConnection",
        "kind": "LinkedField",
        "name": "statusHistory",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "CommentModerationActionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentModerationAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            (v22 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "moderator",
                                "plural": false,
                                "selections": (v24 /*: any*/),
                                "storageKey": null
                            },
                            (v7 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": "statusHistory(first:1)"
    } as any), v34 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "viewerDidModerate",
        "storageKey": null
    } as any), v35 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "statusLiveUpdated",
        "storageKey": null
    } as any), v36 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enteredLive",
        "storageKey": null
    } as any), v37 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "QueueRoutePaginationUnmoderatedQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "ModerationQueues",
                    "kind": "LinkedField",
                    "name": "moderationQueues",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModerationQueue",
                            "kind": "LinkedField",
                            "name": "unmoderated",
                            "plural": false,
                            "selections": [
                                {
                                    "args": [
                                        {
                                            "kind": "Variable",
                                            "name": "count",
                                            "variableName": "count"
                                        },
                                        {
                                            "kind": "Variable",
                                            "name": "cursor",
                                            "variableName": "cursor"
                                        }
                                    ],
                                    "kind": "FragmentSpread",
                                    "name": "QueueRoute_queue"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v4 /*: any*/),
                (v3 /*: any*/),
                (v2 /*: any*/),
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Operation",
            "name": "QueueRoutePaginationUnmoderatedQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "ModerationQueues",
                    "kind": "LinkedField",
                    "name": "moderationQueues",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModerationQueue",
                            "kind": "LinkedField",
                            "name": "unmoderated",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "count",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v6 /*: any*/),
                                    "concreteType": "CommentsConnection",
                                    "kind": "LinkedField",
                                    "name": "comments",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Comment",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v7 /*: any*/),
                                                        (v10 /*: any*/),
                                                        (v11 /*: any*/),
                                                        (v12 /*: any*/),
                                                        (v13 /*: any*/),
                                                        (v20 /*: any*/),
                                                        (v21 /*: any*/),
                                                        (v22 /*: any*/),
                                                        (v23 /*: any*/),
                                                        (v25 /*: any*/),
                                                        (v26 /*: any*/),
                                                        (v27 /*: any*/),
                                                        (v28 /*: any*/),
                                                        (v29 /*: any*/),
                                                        (v30 /*: any*/),
                                                        (v31 /*: any*/),
                                                        (v32 /*: any*/),
                                                        (v33 /*: any*/),
                                                        (v14 /*: any*/),
                                                        {
                                                            "kind": "ClientExtension",
                                                            "selections": [
                                                                (v34 /*: any*/),
                                                                (v35 /*: any*/),
                                                                (v36 /*: any*/)
                                                            ]
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v37 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ClientExtension",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentEdge",
                                                    "kind": "LinkedField",
                                                    "name": "viewNewEdges",
                                                    "plural": true,
                                                    "selections": [
                                                        (v37 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Comment",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v7 /*: any*/),
                                                                (v10 /*: any*/),
                                                                (v35 /*: any*/),
                                                                (v11 /*: any*/),
                                                                (v12 /*: any*/),
                                                                (v13 /*: any*/),
                                                                (v20 /*: any*/),
                                                                (v21 /*: any*/),
                                                                (v22 /*: any*/),
                                                                (v23 /*: any*/),
                                                                (v25 /*: any*/),
                                                                (v26 /*: any*/),
                                                                (v27 /*: any*/),
                                                                (v28 /*: any*/),
                                                                (v29 /*: any*/),
                                                                (v36 /*: any*/),
                                                                (v30 /*: any*/),
                                                                (v31 /*: any*/),
                                                                (v32 /*: any*/),
                                                                (v34 /*: any*/),
                                                                (v33 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ]
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v6 /*: any*/),
                                    "filters": null,
                                    "handle": "connection",
                                    "key": "Queue_comments",
                                    "kind": "LinkedHandle",
                                    "name": "comments"
                                },
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "f9b1eafec5e0544791ebde97d5cb6564",
            "metadata": {},
            "name": "QueueRoutePaginationUnmoderatedQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '916964a5b5b82c7a7b2c9e57e98dd1f4';
export default node;
