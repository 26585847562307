/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StoryRowContainer_viewer = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"StoryActionsContainer_viewer">;
    readonly " $refType": "StoryRowContainer_viewer";
};
export type StoryRowContainer_viewer$data = StoryRowContainer_viewer;
export type StoryRowContainer_viewer$key = {
    readonly " $data"?: StoryRowContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"StoryRowContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StoryRowContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StoryActionsContainer_viewer"
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '4cfa8079c063b8a796ba306bbb52e42a';
export default node;
