import { Localized } from "@fluent/react/compat";
import React, { FunctionComponent, useCallback, useEffect } from "react";
import { graphql, GraphQLTaggedNode, RelayPaginationProp } from "react-relay";

import { SectionFilter } from "coral-common/section";
import parseModerationOptions from "coral-framework/helpers/parseModerationOptions";
import { IntersectionProvider } from "coral-framework/lib/intersection";
import {
  combineDisposables,
  useLoadMore,
  useMutation,
  useSubscription,
  withPaginationContainer,
} from "coral-framework/lib/relay";
import { withRouteConfig } from "coral-framework/lib/router";
import { GQLMODERATION_QUEUE } from "coral-framework/schema";

import { QueueRoute_queue } from "coral-admin/__generated__/QueueRoute_queue.graphql";
import { QueueRoute_settings } from "coral-admin/__generated__/QueueRoute_settings.graphql";
import { QueueRoute_viewer } from "coral-admin/__generated__/QueueRoute_viewer.graphql";
import { QueueRoutePaginationPendingQueryVariables } from "coral-admin/__generated__/QueueRoutePaginationPendingQuery.graphql";

import EmptyMessage from "./EmptyMessage";
import LoadingQueue from "./LoadingQueue";
import Queue from "./Queue";
import QueueCommentEnteredSubscription from "./QueueCommentEnteredSubscription";
import QueueCommentLeftSubscription from "./QueueCommentLeftSubscription";
import QueueViewNewMutation from "./QueueViewNewMutation";

interface Props {
  isLoading: boolean;
  queueName: GQLMODERATION_QUEUE;
  queue: QueueRoute_queue | null;
  settings: QueueRoute_settings | null;
  viewer: QueueRoute_viewer | null;
  relay: RelayPaginationProp;
  emptyElement: React.ReactElement;
  storyID?: string | null;
  siteID?: string | null;
  section?: SectionFilter | null;
}

// TODO: use generated types
const danglingLogic = (status: string) =>
  ["APPROVED", "REJECTED"].includes(status);

export const QueueRoute: FunctionComponent<Props> = ({
  isLoading,
  queueName,
  queue,
  settings,
  viewer,
  relay,
  emptyElement,
  storyID = null,
  siteID = null,
  section,
}) => {
  if(storyID)
    storyID = decodeURIComponent(decodeURIComponent(storyID));
  const [loadMore, isLoadingMore] = useLoadMore(relay, 10);
  const subscribeToQueueCommentEntered = useSubscription(
    QueueCommentEnteredSubscription
  );
  const subscribeToQueueCommentLeft = useSubscription(
    QueueCommentLeftSubscription
  );
  const viewNew = useMutation(QueueViewNewMutation);
  const onViewNew = useCallback(() => {
    void viewNew({
      queue: queueName,
      storyID,
      siteID,
      section,
    });
  }, [queueName, storyID, siteID, viewNew]);

  // Handle subscribing and unsubscribing to the subscriptions.
  useEffect(() => {
    const vars = {
      queue: queueName,
      storyID,
      siteID,
      section,
    };

    const disposable = combineDisposables(
      subscribeToQueueCommentEntered(vars),
      subscribeToQueueCommentLeft(vars)
    );

    return () => {
      disposable.dispose();
    };
  }, [
    storyID,
    siteID,
    section,
    queueName,
    subscribeToQueueCommentEntered,
    subscribeToQueueCommentLeft,
  ]);

  // It's never the case really that the query has loaded but queue or settings
  // is null, but this was to appease the type system.
  if (isLoading || !queue || !settings || !viewer) {
    return <LoadingQueue />;
  }

  const comments = queue.comments.edges.map((edge) => edge.node);

  const viewNewCount =
    (queue.comments.viewNewEdges && queue.comments.viewNewEdges.length) || 0;

  return (
    <IntersectionProvider>
      <Queue
        comments={comments}
        settings={settings}
        viewer={viewer}
        onLoadMore={loadMore}
        hasLoadMore={relay.hasMore()}
        disableLoadMore={isLoadingMore}
        danglingLogic={danglingLogic}
        emptyElement={emptyElement}
        allStories={!storyID}
        viewNewCount={viewNewCount}
        onViewNew={onViewNew}
      />
    </IntersectionProvider>
  );
};

// TODO: (cvle) If this could be autogenerated..
type FragmentVariables = QueueRoutePaginationPendingQueryVariables;

const createQueueRoute = (
  queueName: GQLMODERATION_QUEUE,
  queueQuery: GraphQLTaggedNode,
  paginationQuery: GraphQLTaggedNode,
  emptyElement: React.ReactElement
) => {
  const enhanced = withRouteConfig<Props, any>({
    query: queueQuery,
    cacheConfig: { force: true },
    render: function QueueRouteRender({ Component, data, match }) {
      if (!Component) {
        throw new Error("Missing component");
      }

      let { storyID, siteID, section } = parseModerationOptions(match);
      if(storyID)
        storyID = decodeURIComponent(decodeURIComponent(storyID));

      if (!data) {
        return (
          <Component
            isLoading
            queueName={queueName}
            queue={null}
            settings={null}
            viewer={null}
            emptyElement={emptyElement}
            storyID={storyID}
            siteID={siteID}
            section={section}
          />
        );
      }

      const queue =
        data.moderationQueues[Object.keys(data.moderationQueues)[0]];

      return (
        <Component
          isLoading={false}
          queueName={queueName}
          queue={queue}
          settings={data.settings}
          viewer={data.viewer}
          emptyElement={emptyElement}
          storyID={storyID}
          siteID={siteID}
          section={section}
        />
      );
    },
  })(
    withPaginationContainer<
      Props,
      QueueRoutePaginationPendingQueryVariables,
      FragmentVariables
    >(
      {
        queue: graphql`
          fragment QueueRoute_queue on ModerationQueue
            @argumentDefinitions(
              count: { type: "Int!", defaultValue: 5 }
              cursor: { type: "Cursor" }
            ) {
            count
            comments(first: $count, after: $cursor)
              @connection(key: "Queue_comments") {
              viewNewEdges {
                cursor
                node {
                  id
                  ...ModerateCardContainer_comment
                }
              }
              edges {
                node {
                  id
                  ...ModerateCardContainer_comment
                }
              }
            }
          }
        `,
        settings: graphql`
          fragment QueueRoute_settings on Settings {
            ...ModerateCardContainer_settings
          }
        `,
        viewer: graphql`
          fragment QueueRoute_viewer on User {
            ...ModerateCardContainer_viewer
          }
        `,
      },
      {
        direction: "forward",
        getConnectionFromProps(props) {
          return props.queue && props.queue.comments;
        },
        // This is also the default implementation of `getFragmentVariables` if it isn't provided.
        getFragmentVariables(prevVars, totalCount) {
          return {
            ...prevVars,
            count: totalCount,
          };
        },
        getVariables(props, { count, cursor }, fragmentVariables) {
          return {
            ...fragmentVariables,
            count,
            cursor,
          };
        },
        query: paginationQuery,
      }
    )(QueueRoute)
  );

  return enhanced;
};

export const PendingQueueRoute = createQueueRoute(
  GQLMODERATION_QUEUE.PENDING,
  graphql`
    query QueueRoutePendingQuery(
      $storyID: ID
      $siteID: ID
      $count: Int
      $section: SectionFilter
    ) {
      moderationQueues(storyID: $storyID, siteID: $siteID, section: $section) {
        pending {
          ...QueueRoute_queue @arguments(count: $count)
        }
      }
      settings {
        ...QueueRoute_settings
      }
      viewer {
        ...QueueRoute_viewer
      }
    }
  `,
  graphql`
    # Pagination query to be fetched upon calling 'loadMore'.
    # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
    query QueueRoutePaginationPendingQuery(
      $storyID: ID
      $siteID: ID
      $section: SectionFilter
      $count: Int!
      $cursor: Cursor
    ) {
      moderationQueues(storyID: $storyID, siteID: $siteID, section: $section) {
        pending {
          ...QueueRoute_queue @arguments(count: $count, cursor: $cursor)
        }
      }
    }
  `,
  // eslint-disable-next-line:jsx-wrap-multiline
  <Localized id="moderate-emptyQueue-pending">
    <EmptyMessage>
      Nicely done! There are no more pending comments to moderate.
    </EmptyMessage>
  </Localized>
);

export const ReportedQueueRoute = createQueueRoute(
  GQLMODERATION_QUEUE.REPORTED,
  graphql`
    query QueueRouteReportedQuery(
      $storyID: ID
      $siteID: ID
      $section: SectionFilter
    ) {
      moderationQueues(storyID: $storyID, siteID: $siteID, section: $section) {
        reported {
          ...QueueRoute_queue
        }
      }
      settings {
        ...QueueRoute_settings
      }
      viewer {
        ...QueueRoute_viewer
      }
    }
  `,
  graphql`
    # Pagination query to be fetched upon calling 'loadMore'.
    # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
    query QueueRoutePaginationReportedQuery(
      $storyID: ID
      $siteID: ID
      $section: SectionFilter
      $count: Int!
      $cursor: Cursor
    ) {
      moderationQueues(storyID: $storyID, siteID: $siteID, section: $section) {
        reported {
          ...QueueRoute_queue @arguments(count: $count, cursor: $cursor)
        }
      }
    }
  `,
  // eslint-disable-next-line:jsx-wrap-multiline
  <Localized id="moderate-emptyQueue-reported">
    <EmptyMessage>
      Nicely done! There are no more reported comments to moderate.
    </EmptyMessage>
  </Localized>
);

export const UnmoderatedQueueRoute = createQueueRoute(
  GQLMODERATION_QUEUE.UNMODERATED,
  graphql`
    query QueueRouteUnmoderatedQuery(
      $storyID: ID
      $siteID: ID
      $section: SectionFilter
    ) {
      moderationQueues(storyID: $storyID, siteID: $siteID, section: $section) {
        unmoderated {
          ...QueueRoute_queue
        }
      }
      settings {
        ...QueueRoute_settings
      }
      viewer {
        ...QueueRoute_viewer
      }
    }
  `,
  graphql`
    # Pagination query to be fetched upon calling 'loadMore'.
    # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
    query QueueRoutePaginationUnmoderatedQuery(
      $storyID: ID
      $siteID: ID
      $section: SectionFilter
      $count: Int!
      $cursor: Cursor
    ) {
      moderationQueues(storyID: $storyID, siteID: $siteID, section: $section) {
        unmoderated {
          ...QueueRoute_queue @arguments(count: $count, cursor: $cursor)
        }
      }
    }
  `,
  // eslint-disable-next-line:jsx-wrap-multiline
  <Localized id="moderate-emptyQueue-unmoderated">
    <EmptyMessage>Nicely done! All comments have been moderated.</EmptyMessage>
  </Localized>
);
