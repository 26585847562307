/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EditSiteForm_site = {
    readonly name: string;
    readonly shelfid: string | null;
    readonly createdAt: string;
    readonly id: string;
    readonly allowedOrigins: ReadonlyArray<string>;
    readonly " $refType": "EditSiteForm_site";
};
export type EditSiteForm_site$data = EditSiteForm_site;
export type EditSiteForm_site$key = {
    readonly " $data"?: EditSiteForm_site$data;
    readonly " $fragmentRefs": FragmentRefs<"EditSiteForm_site">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditSiteForm_site",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shelfid",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allowedOrigins",
            "storageKey": null
        }
    ],
    "type": "Site",
    "abstractKey": null
} as any);
(node as any).hash = '797f1d464b47636973cdf4071223aae5';
export default node;
