/* tslint:disable */
/* eslint-disable */
/* @relayHash 6de47a389769ca9b8b5953b22814fc99 */

import { ConcreteRequest } from "relay-runtime";
export type STORY_STATUS = "CLOSED" | "OPEN" | "%future added value";
export type OpenStoryInput = {
    id: string;
    clientMutationId: string;
};
export type OpenStoryMutationVariables = {
    input: OpenStoryInput;
};
export type OpenStoryMutationResponse = {
    readonly openStory: {
        readonly story: {
            readonly id: string;
            readonly status: STORY_STATUS;
            readonly closedAt: string | null;
            readonly isClosed: boolean;
        } | null;
        readonly clientMutationId: string;
    };
};
export type OpenStoryMutation = {
    readonly response: OpenStoryMutationResponse;
    readonly variables: OpenStoryMutationVariables;
};



/*
mutation OpenStoryMutation(
  $input: OpenStoryInput!
) {
  openStory(input: $input) {
    story {
      id
      status
      closedAt
      isClosed
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "OpenStoryPayload",
            "kind": "LinkedField",
            "name": "openStory",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Story",
                    "kind": "LinkedField",
                    "name": "story",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "closedAt",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isClosed",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "OpenStoryMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "OpenStoryMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "6de47a389769ca9b8b5953b22814fc99",
            "metadata": {},
            "name": "OpenStoryMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '0c98d79c1ac29ec9b195e115f90447b1';
export default node;
