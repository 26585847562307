/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModerateCardDetailsContainer_settings = {
    readonly " $fragmentRefs": FragmentRefs<"FlagDetailsContainer_settings" | "LinkDetailsContainer_settings">;
    readonly " $refType": "ModerateCardDetailsContainer_settings";
};
export type ModerateCardDetailsContainer_settings$data = ModerateCardDetailsContainer_settings;
export type ModerateCardDetailsContainer_settings$key = {
    readonly " $data"?: ModerateCardDetailsContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardDetailsContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModerateCardDetailsContainer_settings",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FlagDetailsContainer_settings"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LinkDetailsContainer_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '5fde24b0acdc6f799444c915dc333948';
export default node;
