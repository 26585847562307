/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
export type MarkersContainer_comment = {
    readonly status: COMMENT_STATUS;
    readonly revision: {
        readonly actionCounts: {
            readonly flag: {
                readonly reasons: {
                    readonly COMMENT_REPORTED_SPAM: number;
                    readonly COMMENT_REPORTED_OTHER: number;
                    readonly COMMENT_REPORTED_OFFENSIVE: number;
                    readonly COMMENT_REPORTED_ABUSIVE: number;
                    readonly COMMENT_REPORTED_BIO: number;
                    readonly COMMENT_DETECTED_TOXIC: number;
                    readonly COMMENT_DETECTED_SUSPECT_WORD: number;
                    readonly COMMENT_DETECTED_SPAM: number;
                    readonly COMMENT_DETECTED_REPEAT_POST: number;
                    readonly COMMENT_DETECTED_RECENT_HISTORY: number;
                    readonly COMMENT_DETECTED_NEW_COMMENTER: number;
                    readonly COMMENT_DETECTED_LINKS: number;
                    readonly COMMENT_DETECTED_BANNED_WORD: number;
                };
            };
        };
        readonly metadata: {
            readonly wordList: {
                readonly timedOut: boolean | null;
            } | null;
        };
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardDetailsContainer_comment">;
    readonly " $refType": "MarkersContainer_comment";
};
export type MarkersContainer_comment$data = MarkersContainer_comment;
export type MarkersContainer_comment$key = {
    readonly " $data"?: MarkersContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"MarkersContainer_comment">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkersContainer_comment",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "CommentRevision",
            "kind": "LinkedField",
            "name": "revision",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActionCounts",
                    "kind": "LinkedField",
                    "name": "actionCounts",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "FlagActionCounts",
                            "kind": "LinkedField",
                            "name": "flag",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FlagReasonActionCounts",
                                    "kind": "LinkedField",
                                    "name": "reasons",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_REPORTED_SPAM",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_REPORTED_OTHER",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_REPORTED_OFFENSIVE",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_REPORTED_ABUSIVE",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_REPORTED_BIO",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_DETECTED_TOXIC",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_DETECTED_SUSPECT_WORD",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_DETECTED_SPAM",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_DETECTED_REPEAT_POST",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_DETECTED_RECENT_HISTORY",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_DETECTED_NEW_COMMENTER",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_DETECTED_LINKS",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_DETECTED_BANNED_WORD",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentRevisionMetadata",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CommentRevisionWordListMetadata",
                            "kind": "LinkedField",
                            "name": "wordList",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "timedOut",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModerateCardDetailsContainer_comment"
        }
    ],
    "type": "Comment",
    "abstractKey": null
} as any);
(node as any).hash = 'f724edc2c4e46e277fffef1095a12b92';
export default node;
