/* tslint:disable */
/* eslint-disable */
/* @relayHash bae36a286a4bc8cf44e924d6f370cef3 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SiteModeratorModalSiteFieldContainerPaginationQueryVariables = {
    count: number;
    cursor?: unknown | null;
};
export type SiteModeratorModalSiteFieldContainerPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"SiteModeratorModalSiteFieldContainer_query">;
};
export type SiteModeratorModalSiteFieldContainerPaginationQuery = {
    readonly response: SiteModeratorModalSiteFieldContainerPaginationQueryResponse;
    readonly variables: SiteModeratorModalSiteFieldContainerPaginationQueryVariables;
};



/*
query SiteModeratorModalSiteFieldContainerPaginationQuery(
  $count: Int!
  $cursor: Cursor
) {
  ...SiteModeratorModalSiteFieldContainer_query_1G22uz
}

fragment SiteModeratorModalSiteFieldContainer_query_1G22uz on Query {
  sites(first: $count, after: $cursor) {
    edges {
      node {
        id
        name
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "count"
        } as any),
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SiteModeratorModalSiteFieldContainerPaginationQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "SiteModeratorModalSiteFieldContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SiteModeratorModalSiteFieldContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SitesConnection",
                    "kind": "LinkedField",
                    "name": "sites",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "SiteModeratorModalSiteField_sites",
                    "kind": "LinkedHandle",
                    "name": "sites"
                }
            ]
        },
        "params": {
            "id": "bae36a286a4bc8cf44e924d6f370cef3",
            "metadata": {},
            "name": "SiteModeratorModalSiteFieldContainerPaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '1bddab4fba0d9d01dd54d5a0f0613420';
export default node;
