/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModerateCardContainer_viewer = {
    readonly moderationScopes: {
        readonly scoped: boolean;
    } | null;
    readonly " $refType": "ModerateCardContainer_viewer";
};
export type ModerateCardContainer_viewer$data = ModerateCardContainer_viewer;
export type ModerateCardContainer_viewer$key = {
    readonly " $data"?: ModerateCardContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModerateCardContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "UserModerationScopes",
            "kind": "LinkedField",
            "name": "moderationScopes",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scoped",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '245205632d87056d3216e57a4ddd785e';
export default node;
