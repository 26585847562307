/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserRowContainer_query = {
    readonly " $fragmentRefs": FragmentRefs<"UserRoleChangeContainer_query">;
    readonly " $refType": "UserRowContainer_query";
};
export type UserRowContainer_query$data = UserRowContainer_query;
export type UserRowContainer_query$key = {
    readonly " $data"?: UserRowContainer_query$data;
    readonly " $fragmentRefs": FragmentRefs<"UserRowContainer_query">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserRowContainer_query",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserRoleChangeContainer_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
} as any);
(node as any).hash = '646c1ef12fc1fdf39fa8a4df18c6d21d';
export default node;
