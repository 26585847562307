/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FEATURE_FLAG = "ALTERNATE_OLDEST_FIRST_VIEW" | "AVATARS" | "DEFAULT_QA_STORY_MODE" | "DISABLE_WARN_USER_OF_TOXIC_COMMENT" | "DISCUSSIONS" | "ENABLE_QA" | "ENABLE_RATINGS_AND_REVIEWS" | "EXTERNAL_MEDIA" | "NEW_COMMENT_COUNT" | "READ_MORE_NEW_TAB" | "REDUCED_SECURITY_MODE" | "RTE_SARCASM" | "SECTIONS" | "SITE_MODERATOR" | "VIEWER_COUNT" | "%future added value";
export type ConversationModalCommentContainer_settings = {
    readonly multisite: boolean;
    readonly featureFlags: ReadonlyArray<FEATURE_FLAG>;
    readonly " $fragmentRefs": FragmentRefs<"MarkersContainer_settings">;
    readonly " $refType": "ConversationModalCommentContainer_settings";
};
export type ConversationModalCommentContainer_settings$data = ConversationModalCommentContainer_settings;
export type ConversationModalCommentContainer_settings$key = {
    readonly " $data"?: ConversationModalCommentContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"ConversationModalCommentContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConversationModalCommentContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "multisite",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "featureFlags",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MarkersContainer_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '47158a15640bf211289f1a428910552b';
export default node;
