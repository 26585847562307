/* tslint:disable */
/* eslint-disable */
/* @relayHash 0afbd91067c721191a61a619d5069de0 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SiteRouteQueryVariables = {
    siteID: string;
};
export type SiteRouteQueryResponse = {
    readonly site: {
        readonly name: string;
        readonly shelfid: string | null;
        readonly " $fragmentRefs": FragmentRefs<"EditSiteForm_site">;
    } | null;
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"EditSiteForm_settings">;
    };
};
export type SiteRouteQuery = {
    readonly response: SiteRouteQueryResponse;
    readonly variables: SiteRouteQueryVariables;
};



/*
query SiteRouteQuery(
  $siteID: ID!
) {
  site(id: $siteID) {
    name
    shelfid
    ...EditSiteForm_site
    id
  }
  settings {
    ...EditSiteForm_settings
    id
  }
}

fragment EditSiteForm_settings on Settings {
  staticURI
}

fragment EditSiteForm_site on Site {
  name
  shelfid
  createdAt
  id
  allowedOrigins
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "siteID"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "siteID"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shelfid",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SiteRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditSiteForm_site"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditSiteForm_settings"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SiteRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                        },
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "allowedOrigins",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "staticURI",
                            "storageKey": null
                        },
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "0afbd91067c721191a61a619d5069de0",
            "metadata": {},
            "name": "SiteRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'e920db6d5ec41ae25d227e0d3e1fc8cf';
export default node;
