/* tslint:disable */
/* eslint-disable */
/* @relayHash 1e800bd9449656a91a907ebd655a428a */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DashboardContainerPaginationQueryVariables = {
    count: number;
    cursor?: unknown | null;
};
export type DashboardContainerPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"DashboardContainer_query">;
};
export type DashboardContainerPaginationQuery = {
    readonly response: DashboardContainerPaginationQueryResponse;
    readonly variables: DashboardContainerPaginationQueryVariables;
};



/*
query DashboardContainerPaginationQuery(
  $count: Int!
  $cursor: Cursor
) {
  ...DashboardContainer_query_1G22uz
}

fragment DashboardContainer_query_1G22uz on Query {
  sites(first: $count, after: $cursor) {
    edges {
      node {
        id
        name
        ...DashboardSiteContainer_site
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  viewer {
    role
    moderationScopes {
      scoped
      sites {
        id
        name
        ...SiteSelectorSite_site
      }
    }
    id
  }
}

fragment DashboardSiteContainer_site on Site {
  id
  name
  createdAt
}

fragment SiteSelectorSite_site on Site {
  name
  id
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "count"
        } as any),
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DashboardContainerPaginationQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "DashboardContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DashboardContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SitesConnection",
                    "kind": "LinkedField",
                    "name": "sites",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "SitesConfig_sites",
                    "kind": "LinkedHandle",
                    "name": "sites"
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "scoped",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "sites",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "1e800bd9449656a91a907ebd655a428a",
            "metadata": {},
            "name": "DashboardContainerPaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '9c15f12bb148d20f21b7a673664627bc';
export default node;
