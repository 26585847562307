/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserStatusChangeContainer_viewer = {
    readonly moderationScopes: {
        readonly scoped: boolean;
    } | null;
    readonly " $refType": "UserStatusChangeContainer_viewer";
};
export type UserStatusChangeContainer_viewer$data = UserStatusChangeContainer_viewer;
export type UserStatusChangeContainer_viewer$key = {
    readonly " $data"?: UserStatusChangeContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"UserStatusChangeContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserStatusChangeContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "UserModerationScopes",
            "kind": "LinkedField",
            "name": "moderationScopes",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scoped",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '55dbb4e2cd314919ce532ce66643c21d';
export default node;
