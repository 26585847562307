/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MODERATION_MODE = "POST" | "PRE" | "%future added value";
export type PreModerationConfig_formValues = {
    readonly moderation: MODERATION_MODE | null;
    readonly premodLinksEnable: boolean | null;
    readonly " $refType": "PreModerationConfig_formValues";
};
export type PreModerationConfig_formValues$data = PreModerationConfig_formValues;
export type PreModerationConfig_formValues$key = {
    readonly " $data"?: PreModerationConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"PreModerationConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PreModerationConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "moderation",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "premodLinksEnable",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'e8b4e469b4d8085b27c51b9f172da014';
export default node;
