/* tslint:disable */
/* eslint-disable */
/* @relayHash d1c78737a778a7304caaf87b1320a68e */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type STORY_STATUS = "CLOSED" | "OPEN" | "%future added value";
export type StoryTableContainerPaginationQueryVariables = {
    count: number;
    cursor?: unknown | null;
    statusFilter?: STORY_STATUS | null;
    searchFilter?: string | null;
    siteID?: string | null;
};
export type StoryTableContainerPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"StoryTableContainer_query">;
};
export type StoryTableContainerPaginationQuery = {
    readonly response: StoryTableContainerPaginationQueryResponse;
    readonly variables: StoryTableContainerPaginationQueryVariables;
};



/*
query StoryTableContainerPaginationQuery(
  $count: Int!
  $cursor: Cursor
  $statusFilter: STORY_STATUS
  $searchFilter: String
  $siteID: ID
) {
  ...StoryTableContainer_query_2Ry5jP
}

fragment SiteFilterContainer_query on Query {
  sites(first: 10) {
    edges {
      node {
        id
        ...SiteFilterOption_site
        ...SiteFilterSelected_site
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment SiteFilterOption_site on Site {
  name
  id
}

fragment SiteFilterSelected_site on Site {
  name
}

fragment SiteSelectorSite_site on Site {
  name
  id
}

fragment StoryActionsContainer_story on Story {
  id
  status
}

fragment StoryActionsContainer_viewer on User {
  id
  role
}

fragment StoryRowContainer_story on Story {
  id
  metadata {
    title
    author
    publishedAt
  }
  commentCounts {
    totalPublished
  }
  moderationQueues {
    reported {
      count
      id
    }
    pending {
      count
      id
    }
  }
  viewerCount
  site {
    name
    id
  }
  canModerate
  isClosed
  ...StoryActionsContainer_story
  ...StoryStatusContainer_story
}

fragment StoryRowContainer_viewer on User {
  id
  ...StoryActionsContainer_viewer
}

fragment StoryStatusContainer_story on Story {
  id
  status
}

fragment StoryTableContainer_query_2Ry5jP on Query {
  viewer {
    role
    moderationScopes {
      scoped
      sites {
        id
        name
        ...SiteSelectorSite_site
      }
    }
    ...StoryRowContainer_viewer
    id
  }
  settings {
    multisite
    id
  }
  ...SiteFilterContainer_query
  stories(first: $count, after: $cursor, status: $statusFilter, query: $searchFilter, siteID: $siteID) {
    edges {
      node {
        id
        ...StoryRowContainer_story
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "count"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "cursor"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "searchFilter"
    } as any), v3 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "siteID"
    } as any), v4 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "statusFilter"
    } as any), v5 = ({
        "kind": "Variable",
        "name": "siteID",
        "variableName": "siteID"
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any), v8 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 10
        } as any)
    ], v9 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any), v10 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    } as any), v11 = ({
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v12 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any),
        ({
            "kind": "Variable",
            "name": "query",
            "variableName": "searchFilter"
        } as any),
        (v5 /*: any*/),
        ({
            "kind": "Variable",
            "name": "status",
            "variableName": "statusFilter"
        } as any)
    ], v13 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
        } as any),
        (v6 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "StoryTableContainerPaginationQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        },
                        {
                            "kind": "Variable",
                            "name": "searchFilter",
                            "variableName": "searchFilter"
                        },
                        (v5 /*: any*/),
                        {
                            "kind": "Variable",
                            "name": "statusFilter",
                            "variableName": "statusFilter"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "StoryTableContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v4 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Operation",
            "name": "StoryTableContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "scoped",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "sites",
                                    "plural": true,
                                    "selections": [
                                        (v6 /*: any*/),
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v6 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "multisite",
                            "storageKey": null
                        },
                        (v6 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v8 /*: any*/),
                    "concreteType": "SitesConnection",
                    "kind": "LinkedField",
                    "name": "sites",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v9 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v10 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v11 /*: any*/)
                    ],
                    "storageKey": "sites(first:10)"
                },
                {
                    "alias": null,
                    "args": (v8 /*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "SitesConfig_sites",
                    "kind": "LinkedHandle",
                    "name": "sites"
                },
                {
                    "alias": null,
                    "args": (v12 /*: any*/),
                    "concreteType": "StoriesConnection",
                    "kind": "LinkedField",
                    "name": "stories",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "StoryEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Story",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v6 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "StoryMetadata",
                                            "kind": "LinkedField",
                                            "name": "metadata",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "title",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "author",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "publishedAt",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentCounts",
                                            "kind": "LinkedField",
                                            "name": "commentCounts",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "totalPublished",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ModerationQueues",
                                            "kind": "LinkedField",
                                            "name": "moderationQueues",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ModerationQueue",
                                                    "kind": "LinkedField",
                                                    "name": "reported",
                                                    "plural": false,
                                                    "selections": (v13 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ModerationQueue",
                                                    "kind": "LinkedField",
                                                    "name": "pending",
                                                    "plural": false,
                                                    "selections": (v13 /*: any*/),
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "viewerCount",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Site",
                                            "kind": "LinkedField",
                                            "name": "site",
                                            "plural": false,
                                            "selections": [
                                                (v7 /*: any*/),
                                                (v6 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "canModerate",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isClosed",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "status",
                                            "storageKey": null
                                        },
                                        (v9 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v10 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v11 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v12 /*: any*/),
                    "filters": [
                        "status",
                        "query",
                        "siteID"
                    ],
                    "handle": "connection",
                    "key": "StoryTable_stories",
                    "kind": "LinkedHandle",
                    "name": "stories"
                }
            ]
        },
        "params": {
            "id": "d1c78737a778a7304caaf87b1320a68e",
            "metadata": {},
            "name": "StoryTableContainerPaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '30f674cb0c798c631e7cfcc81cbcd5ae';
export default node;
