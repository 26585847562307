/* tslint:disable */
/* eslint-disable */
/* @relayHash e083fe43f4eece9cbbbd4e8a851e17bf */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserHistoryDrawerAllCommentsPaginationQueryVariables = {
    userID: string;
    count: number;
    cursor?: unknown | null;
};
export type UserHistoryDrawerAllCommentsPaginationQueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"UserHistoryDrawerAllComments_user">;
    } | null;
};
export type UserHistoryDrawerAllCommentsPaginationQuery = {
    readonly response: UserHistoryDrawerAllCommentsPaginationQueryResponse;
    readonly variables: UserHistoryDrawerAllCommentsPaginationQueryVariables;
};



/*
query UserHistoryDrawerAllCommentsPaginationQuery(
  $userID: ID!
  $count: Int!
  $cursor: Cursor
) {
  user(id: $userID) {
    ...UserHistoryDrawerAllComments_user_1G22uz
    id
  }
}

fragment CommentAuthorContainer_comment on Comment {
  author {
    id
    username
    status {
      suspension {
        active
      }
      premod {
        active
      }
      ban {
        active
      }
    }
  }
}

fragment CommentRevisionContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    id
  }
  revisionHistory {
    id
    body
    createdAt
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
  }
}

fragment FlagDetailsContainer_comment on Comment {
  flags {
    nodes {
      flagger {
        username
        id
      }
      reason
      additionalDetails
    }
  }
  revision {
    metadata {
      perspective {
        score
      }
    }
    id
  }
}

fragment LinkDetailsContainer_comment on Comment {
  id
  story {
    id
    url
  }
}

fragment MarkersContainer_comment on Comment {
  ...ModerateCardDetailsContainer_comment
  status
  revision {
    actionCounts {
      flag {
        reasons {
          COMMENT_REPORTED_SPAM
          COMMENT_REPORTED_OTHER
          COMMENT_REPORTED_OFFENSIVE
          COMMENT_REPORTED_ABUSIVE
          COMMENT_REPORTED_BIO
          COMMENT_DETECTED_TOXIC
          COMMENT_DETECTED_SUSPECT_WORD
          COMMENT_DETECTED_SPAM
          COMMENT_DETECTED_REPEAT_POST
          COMMENT_DETECTED_RECENT_HISTORY
          COMMENT_DETECTED_NEW_COMMENTER
          COMMENT_DETECTED_LINKS
          COMMENT_DETECTED_BANNED_WORD
        }
      }
    }
    metadata {
      wordList {
        timedOut
      }
    }
    id
  }
}

fragment MediaContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
    id
  }
}

fragment ModerateCardContainer_comment on Comment {
  id
  author {
    id
    username
    status {
      current
    }
  }
  createdAt
  body
  rating
  revision {
    actionCounts {
      flag {
        reasons {
          COMMENT_DETECTED_BANNED_WORD
          COMMENT_DETECTED_SUSPECT_WORD
        }
      }
    }
    id
  }
  tags {
    code
  }
  status
  editing {
    edited
  }
  parent {
    author {
      id
      username
    }
    id
  }
  canModerate
  story {
    id
    metadata {
      title
    }
    settings {
      mode
    }
  }
  site {
    id
    name
  }
  permalink
  deleted
  ...MarkersContainer_comment
  ...ModeratedByContainer_comment
  ...CommentAuthorContainer_comment
  ...MediaContainer_comment
}

fragment ModerateCardDetailsContainer_comment on Comment {
  editing {
    edited
  }
  revision {
    actionCounts {
      flag {
        reasons {
          COMMENT_REPORTED_OFFENSIVE
          COMMENT_REPORTED_ABUSIVE
          COMMENT_REPORTED_SPAM
          COMMENT_REPORTED_OTHER
        }
      }
    }
    metadata {
      perspective {
        score
      }
    }
    id
  }
  ...FlagDetailsContainer_comment
  ...CommentRevisionContainer_comment
  ...LinkDetailsContainer_comment
}

fragment ModeratedByContainer_comment on Comment {
  id
  status
  statusHistory(first: 1) {
    edges {
      node {
        status
        moderator {
          id
          username
        }
        id
      }
    }
  }
}

fragment UserHistoryDrawerAllComments_user_1G22uz on User {
  username
  email
  allComments(first: $count, after: $cursor) {
    edges {
      node {
        id
        ...ModerateCardContainer_comment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "count"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "cursor"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "userID"
    } as any), v3 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "userID"
        } as any)
    ], v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v5 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any)
    ], v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v7 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
        } as any)
    ], v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any), v9 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
    } as any), v10 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any), v11 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v12 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any), v13 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any), v14 = [
        (v11 /*: any*/)
    ], v15 = ({
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "media",
        "plural": false,
        "selections": [
            (v10 /*: any*/),
            {
                "kind": "InlineFragment",
                "selections": [
                    (v11 /*: any*/),
                    (v12 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "width",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "height",
                        "storageKey": null
                    },
                    (v13 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "video",
                        "storageKey": null
                    }
                ],
                "type": "GiphyMedia",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": (v14 /*: any*/),
                "type": "TwitterMedia",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v11 /*: any*/),
                    (v13 /*: any*/),
                    (v12 /*: any*/)
                ],
                "type": "YouTubeMedia",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": (v14 /*: any*/),
                "type": "ExternalMedia",
                "abstractKey": null
            }
        ],
        "storageKey": null
    } as any), v16 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any), v17 = [
        (v6 /*: any*/),
        (v4 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "UserHistoryDrawerAllCommentsPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "count",
                                    "variableName": "count"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "cursor",
                                    "variableName": "cursor"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "UserHistoryDrawerAllComments_user"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v2 /*: any*/),
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Operation",
            "name": "UserHistoryDrawerAllCommentsPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v5 /*: any*/),
                            "concreteType": "CommentsConnection",
                            "kind": "LinkedField",
                            "name": "allComments",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Comment",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v6 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "author",
                                                    "plural": false,
                                                    "selections": [
                                                        (v6 /*: any*/),
                                                        (v4 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "UserStatus",
                                                            "kind": "LinkedField",
                                                            "name": "status",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "current",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "SuspensionStatus",
                                                                    "kind": "LinkedField",
                                                                    "name": "suspension",
                                                                    "plural": false,
                                                                    "selections": (v7 /*: any*/),
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "PremodStatus",
                                                                    "kind": "LinkedField",
                                                                    "name": "premod",
                                                                    "plural": false,
                                                                    "selections": (v7 /*: any*/),
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "BanStatus",
                                                                    "kind": "LinkedField",
                                                                    "name": "ban",
                                                                    "plural": false,
                                                                    "selections": (v7 /*: any*/),
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v8 /*: any*/),
                                                (v9 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "rating",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevision",
                                                    "kind": "LinkedField",
                                                    "name": "revision",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ActionCounts",
                                                            "kind": "LinkedField",
                                                            "name": "actionCounts",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "FlagActionCounts",
                                                                    "kind": "LinkedField",
                                                                    "name": "flag",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "FlagReasonActionCounts",
                                                                            "kind": "LinkedField",
                                                                            "name": "reasons",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_BANNED_WORD",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_SUSPECT_WORD",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_REPORTED_OFFENSIVE",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_REPORTED_ABUSIVE",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_REPORTED_SPAM",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_REPORTED_OTHER",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_REPORTED_BIO",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_TOXIC",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_SPAM",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_REPEAT_POST",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_RECENT_HISTORY",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_NEW_COMMENTER",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "COMMENT_DETECTED_LINKS",
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v6 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "CommentRevisionMetadata",
                                                            "kind": "LinkedField",
                                                            "name": "metadata",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "CommentRevisionPerspectiveMetadata",
                                                                    "kind": "LinkedField",
                                                                    "name": "perspective",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "score",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "CommentRevisionWordListMetadata",
                                                                    "kind": "LinkedField",
                                                                    "name": "wordList",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "timedOut",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v15 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Tag",
                                                    "kind": "LinkedField",
                                                    "name": "tags",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "code",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v16 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "EditInfo",
                                                    "kind": "LinkedField",
                                                    "name": "editing",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "edited",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Comment",
                                                    "kind": "LinkedField",
                                                    "name": "parent",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "kind": "LinkedField",
                                                            "name": "author",
                                                            "plural": false,
                                                            "selections": (v17 /*: any*/),
                                                            "storageKey": null
                                                        },
                                                        (v6 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "canModerate",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Story",
                                                    "kind": "LinkedField",
                                                    "name": "story",
                                                    "plural": false,
                                                    "selections": [
                                                        (v6 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "StoryMetadata",
                                                            "kind": "LinkedField",
                                                            "name": "metadata",
                                                            "plural": false,
                                                            "selections": [
                                                                (v12 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "StorySettings",
                                                            "kind": "LinkedField",
                                                            "name": "settings",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "mode",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v11 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Site",
                                                    "kind": "LinkedField",
                                                    "name": "site",
                                                    "plural": false,
                                                    "selections": [
                                                        (v6 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "name",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "permalink",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "deleted",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "FlagsConnection",
                                                    "kind": "LinkedField",
                                                    "name": "flags",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Flag",
                                                            "kind": "LinkedField",
                                                            "name": "nodes",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "User",
                                                                    "kind": "LinkedField",
                                                                    "name": "flagger",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v4 /*: any*/),
                                                                        (v6 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "reason",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "additionalDetails",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevision",
                                                    "kind": "LinkedField",
                                                    "name": "revisionHistory",
                                                    "plural": true,
                                                    "selections": [
                                                        (v6 /*: any*/),
                                                        (v9 /*: any*/),
                                                        (v8 /*: any*/),
                                                        (v15 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": [
                                                        {
                                                            "kind": "Literal",
                                                            "name": "first",
                                                            "value": 1
                                                        }
                                                    ],
                                                    "concreteType": "CommentModerationActionConnection",
                                                    "kind": "LinkedField",
                                                    "name": "statusHistory",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "CommentModerationActionEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "CommentModerationAction",
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v16 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "User",
                                                                            "kind": "LinkedField",
                                                                            "name": "moderator",
                                                                            "plural": false,
                                                                            "selections": (v17 /*: any*/),
                                                                            "storageKey": null
                                                                        },
                                                                        (v6 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": "statusHistory(first:1)"
                                                },
                                                (v10 /*: any*/),
                                                {
                                                    "kind": "ClientExtension",
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "viewerDidModerate",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "statusLiveUpdated",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "enteredLive",
                                                            "storageKey": null
                                                        }
                                                    ]
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v5 /*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "UserHistoryDrawer_allComments",
                            "kind": "LinkedHandle",
                            "name": "allComments"
                        },
                        (v6 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "e083fe43f4eece9cbbbd4e8a851e17bf",
            "metadata": {},
            "name": "UserHistoryDrawerAllCommentsPaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '0ac604ae7cdcbaf3b6270927988e3bf7';
export default node;
