/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DecisionHistoryContainer_viewer = {
    readonly commentModerationActionHistory: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"DecisionHistoryItemContainer_action">;
            };
        }>;
    };
    readonly " $refType": "DecisionHistoryContainer_viewer";
};
export type DecisionHistoryContainer_viewer$data = DecisionHistoryContainer_viewer;
export type DecisionHistoryContainer_viewer$key = {
    readonly " $data"?: DecisionHistoryContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"DecisionHistoryContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [
        {
            "defaultValue": 5,
            "kind": "LocalArgument",
            "name": "count"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "connection": [
            {
                "count": "count",
                "cursor": "cursor",
                "direction": "forward",
                "path": [
                    "commentModerationActionHistory"
                ]
            }
        ]
    },
    "name": "DecisionHistoryContainer_viewer",
    "selections": [
        {
            "alias": "commentModerationActionHistory",
            "args": null,
            "concreteType": "CommentModerationActionConnection",
            "kind": "LinkedField",
            "name": "__DecisionHistory_commentModerationActionHistory_connection",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentModerationActionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CommentModerationAction",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "DecisionHistoryItemContainer_action"
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '7b0bc3a77652e200dc68c4d028f9c79c';
export default node;
