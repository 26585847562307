/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ApprovedQueueRoute_query = {
    readonly comments: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_comment">;
            };
        }>;
    };
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_settings">;
    };
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_viewer">;
    } | null;
    readonly " $refType": "ApprovedQueueRoute_query";
};
export type ApprovedQueueRoute_query$data = ApprovedQueueRoute_query;
export type ApprovedQueueRoute_query$key = {
    readonly " $data"?: ApprovedQueueRoute_query$data;
    readonly " $fragmentRefs": FragmentRefs<"ApprovedQueueRoute_query">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [
        {
            "defaultValue": 5,
            "kind": "LocalArgument",
            "name": "count"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "section"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "siteID"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "storyID"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "connection": [
            {
                "count": "count",
                "cursor": "cursor",
                "direction": "forward",
                "path": [
                    "comments"
                ]
            }
        ]
    },
    "name": "ApprovedQueueRoute_query",
    "selections": [
        {
            "alias": "comments",
            "args": [
                {
                    "kind": "Variable",
                    "name": "section",
                    "variableName": "section"
                },
                {
                    "kind": "Variable",
                    "name": "siteID",
                    "variableName": "siteID"
                },
                {
                    "kind": "Literal",
                    "name": "status",
                    "value": "APPROVED"
                },
                {
                    "kind": "Variable",
                    "name": "storyID",
                    "variableName": "storyID"
                }
            ],
            "concreteType": "CommentsConnection",
            "kind": "LinkedField",
            "name": "__ApprovedQueue_comments_connection",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ModerateCardContainer_comment"
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Settings",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ModerateCardContainer_settings"
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ModerateCardContainer_viewer"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
} as any);
(node as any).hash = 'f0e4f53752b6e38c27015cec6746f1dd';
export default node;
