/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserDrawerAccountHistory_user = {
    readonly status: {
        readonly username: {
            readonly history: ReadonlyArray<{
                readonly username: string;
                readonly createdAt: string;
                readonly createdBy: {
                    readonly username: string | null;
                };
            }>;
        };
        readonly warning: {
            readonly history: ReadonlyArray<{
                readonly active: boolean;
                readonly createdBy: {
                    readonly username: string | null;
                };
                readonly acknowledgedAt: string | null;
                readonly createdAt: string;
                readonly message: string | null;
            }>;
        };
        readonly ban: {
            readonly history: ReadonlyArray<{
                readonly active: boolean;
                readonly createdBy: {
                    readonly username: string | null;
                } | null;
                readonly createdAt: string;
            }>;
        };
        readonly premod: {
            readonly history: ReadonlyArray<{
                readonly active: boolean;
                readonly createdBy: {
                    readonly username: string | null;
                };
                readonly createdAt: string;
            }>;
        };
        readonly suspension: {
            readonly history: ReadonlyArray<{
                readonly active: boolean;
                readonly from: {
                    readonly start: string;
                    readonly finish: string;
                };
                readonly createdBy: {
                    readonly username: string | null;
                } | null;
                readonly modifiedAt: string | null;
                readonly modifiedBy: {
                    readonly username: string | null;
                } | null;
                readonly createdAt: string;
            }>;
        };
    };
    readonly " $refType": "UserDrawerAccountHistory_user";
};
export type UserDrawerAccountHistory_user$data = UserDrawerAccountHistory_user;
export type UserDrawerAccountHistory_user$key = {
    readonly " $data"?: UserDrawerAccountHistory_user$data;
    readonly " $fragmentRefs": FragmentRefs<"UserDrawerAccountHistory_user">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any), v2 = [
        (v0 /*: any*/)
    ], v3 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "createdBy",
        "plural": false,
        "selections": (v2 /*: any*/),
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
    } as any), v5 = [
        (v4 /*: any*/),
        (v3 /*: any*/),
        (v1 /*: any*/)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "UserDrawerAccountHistory_user",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "UserStatus",
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "UsernameStatus",
                        "kind": "LinkedField",
                        "name": "username",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "UsernameHistory",
                                "kind": "LinkedField",
                                "name": "history",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    (v1 /*: any*/),
                                    (v3 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "WarningStatus",
                        "kind": "LinkedField",
                        "name": "warning",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "WarningStatusHistory",
                                "kind": "LinkedField",
                                "name": "history",
                                "plural": true,
                                "selections": [
                                    (v4 /*: any*/),
                                    (v3 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "acknowledgedAt",
                                        "storageKey": null
                                    },
                                    (v1 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "message",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "BanStatus",
                        "kind": "LinkedField",
                        "name": "ban",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "BanStatusHistory",
                                "kind": "LinkedField",
                                "name": "history",
                                "plural": true,
                                "selections": (v5 /*: any*/),
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PremodStatus",
                        "kind": "LinkedField",
                        "name": "premod",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PremodStatusHistory",
                                "kind": "LinkedField",
                                "name": "history",
                                "plural": true,
                                "selections": (v5 /*: any*/),
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SuspensionStatus",
                        "kind": "LinkedField",
                        "name": "suspension",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "SuspensionStatusHistory",
                                "kind": "LinkedField",
                                "name": "history",
                                "plural": true,
                                "selections": [
                                    (v4 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "TimeRange",
                                        "kind": "LinkedField",
                                        "name": "from",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "start",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "finish",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    (v3 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "modifiedAt",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "User",
                                        "kind": "LinkedField",
                                        "name": "modifiedBy",
                                        "plural": false,
                                        "selections": (v2 /*: any*/),
                                        "storageKey": null
                                    },
                                    (v1 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "User",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '3abe956b480d2af0b7ac592132e58f7f';
export default node;
