/* tslint:disable */
/* eslint-disable */
/* @relayHash e8161d2f82d9e2d340dcdf6b58929df5 */

import { ConcreteRequest } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MODERATOR" | "STAFF" | "%future added value";
export type PromoteUserInput = {
    userID: string;
    clientMutationId: string;
};
export type PromoteUserMutationVariables = {
    input: PromoteUserInput;
};
export type PromoteUserMutationResponse = {
    readonly promoteUser: {
        readonly user: {
            readonly id: string;
            readonly role: USER_ROLE;
            readonly moderationScopes: {
                readonly scoped: boolean;
                readonly sites: ReadonlyArray<{
                    readonly id: string;
                    readonly name: string;
                }> | null;
            } | null;
        };
        readonly clientMutationId: string;
    };
};
export type PromoteUserMutation = {
    readonly response: PromoteUserMutationResponse;
    readonly variables: PromoteUserMutationVariables;
};



/*
mutation PromoteUserMutation(
  $input: PromoteUserInput!
) {
  promoteUser(input: $input) {
    user {
      id
      role
      moderationScopes {
        scoped
        sites {
          id
          name
        }
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v2 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "PromoteUserPayload",
            "kind": "LinkedField",
            "name": "promoteUser",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "scoped",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "sites",
                                    "plural": true,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "PromoteUserMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "PromoteUserMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "id": "e8161d2f82d9e2d340dcdf6b58929df5",
            "metadata": {},
            "name": "PromoteUserMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '6a9d4ad4cb516eed34a105f3b313db89';
export default node;
