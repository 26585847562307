/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
export type ModeratedByContainer_comment = {
    readonly id: string;
    readonly status: COMMENT_STATUS;
    readonly viewerDidModerate: boolean | null;
    readonly statusHistory: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly status: COMMENT_STATUS;
                readonly moderator: {
                    readonly id: string;
                    readonly username: string | null;
                } | null;
            };
        }>;
    };
    readonly " $refType": "ModeratedByContainer_comment";
};
export type ModeratedByContainer_comment$data = ModeratedByContainer_comment;
export type ModeratedByContainer_comment$key = {
    readonly " $data"?: ModeratedByContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"ModeratedByContainer_comment">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ModeratedByContainer_comment",
        "selections": [
            (v0 /*: any*/),
            (v1 /*: any*/),
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1
                    }
                ],
                "concreteType": "CommentModerationActionConnection",
                "kind": "LinkedField",
                "name": "statusHistory",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentModerationActionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "CommentModerationAction",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v1 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "User",
                                        "kind": "LinkedField",
                                        "name": "moderator",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "username",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": "statusHistory(first:1)"
            },
            {
                "kind": "ClientExtension",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewerDidModerate",
                        "storageKey": null
                    }
                ]
            }
        ],
        "type": "Comment",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '7805817258bdcd6f4ae2b95a46be1977';
export default node;
