/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserRoleChangeContainer_query = {
    readonly " $fragmentRefs": FragmentRefs<"SiteModeratorModalSiteFieldContainer_query">;
    readonly " $refType": "UserRoleChangeContainer_query";
};
export type UserRoleChangeContainer_query$data = UserRoleChangeContainer_query;
export type UserRoleChangeContainer_query$key = {
    readonly " $data"?: UserRoleChangeContainer_query$data;
    readonly " $fragmentRefs": FragmentRefs<"UserRoleChangeContainer_query">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserRoleChangeContainer_query",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SiteModeratorModalSiteFieldContainer_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
} as any);
(node as any).hash = 'c6862e8eb38492085fd651ec64600964';
export default node;
