/* tslint:disable */
/* eslint-disable */
/* @relayHash c1394f063707ed6df6250997c88a188b */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DecisionHistoryQueryVariables = {};
export type DecisionHistoryQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"DecisionHistoryContainer_viewer">;
    } | null;
};
export type DecisionHistoryQuery = {
    readonly response: DecisionHistoryQueryResponse;
    readonly variables: DecisionHistoryQueryVariables;
};



/*
query DecisionHistoryQuery {
  viewer {
    ...DecisionHistoryContainer_viewer
    id
  }
}

fragment DecisionHistoryContainer_viewer on User {
  commentModerationActionHistory(first: 5) {
    edges {
      node {
        id
        ...DecisionHistoryItemContainer_action
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment DecisionHistoryItemContainer_action on CommentModerationAction {
  id
  revision {
    id
    comment {
      id
      author {
        username
        id
      }
    }
  }
  createdAt
  status
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 5
        } as any)
    ], v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "DecisionHistoryQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "DecisionHistoryContainer_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "DecisionHistoryQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": (v0 /*: any*/),
                            "concreteType": "CommentModerationActionConnection",
                            "kind": "LinkedField",
                            "name": "commentModerationActionHistory",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentModerationActionEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentModerationAction",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v1 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevision",
                                                    "kind": "LinkedField",
                                                    "name": "revision",
                                                    "plural": false,
                                                    "selections": [
                                                        (v1 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Comment",
                                                            "kind": "LinkedField",
                                                            "name": "comment",
                                                            "plural": false,
                                                            "selections": [
                                                                (v1 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "User",
                                                                    "kind": "LinkedField",
                                                                    "name": "author",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "username",
                                                                            "storageKey": null
                                                                        },
                                                                        (v1 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "createdAt",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "status",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "commentModerationActionHistory(first:5)"
                        },
                        {
                            "alias": null,
                            "args": (v0 /*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "DecisionHistory_commentModerationActionHistory",
                            "kind": "LinkedHandle",
                            "name": "commentModerationActionHistory"
                        },
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "c1394f063707ed6df6250997c88a188b",
            "metadata": {},
            "name": "DecisionHistoryQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'a955e0079248c9c8f3dc54b434f05688';
export default node;
