/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SiteFilterSelected_site = {
    readonly name: string;
    readonly " $refType": "SiteFilterSelected_site";
};
export type SiteFilterSelected_site$data = SiteFilterSelected_site;
export type SiteFilterSelected_site$key = {
    readonly " $data"?: SiteFilterSelected_site$data;
    readonly " $fragmentRefs": FragmentRefs<"SiteFilterSelected_site">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteFilterSelected_site",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        }
    ],
    "type": "Site",
    "abstractKey": null
} as any);
(node as any).hash = '0dea708a4e2bdf21b0f6c1ec7370595c';
export default node;
