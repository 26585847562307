/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type USER_STATUS = "ACTIVE" | "BANNED" | "PREMOD" | "SUSPENDED" | "WARNED" | "%future added value";
export type UserStatusContainer_user = {
    readonly status: {
        readonly current: ReadonlyArray<USER_STATUS>;
    };
    readonly " $refType": "UserStatusContainer_user";
};
export type UserStatusContainer_user$data = UserStatusContainer_user;
export type UserStatusContainer_user$key = {
    readonly " $data"?: UserStatusContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"UserStatusContainer_user">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserStatusContainer_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "UserStatus",
            "kind": "LinkedField",
            "name": "status",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "current",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '5aeff630f1ea53ffc7412032e11c7f90';
export default node;
