/* tslint:disable */
/* eslint-disable */
/* @relayHash 26f4c0f758829a7022bf7c26ba3caf2d */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
export type SingleModerateSubscriptionVariables = {
    commentID: string;
};
export type SingleModerateSubscriptionResponse = {
    readonly commentStatusUpdated: {
        readonly comment: {
            readonly id: string;
            readonly status: COMMENT_STATUS;
            readonly revision: {
                readonly actionCounts: {
                    readonly flag: {
                        readonly reasons: {
                            readonly COMMENT_REPORTED_SPAM: number;
                            readonly COMMENT_REPORTED_OTHER: number;
                            readonly COMMENT_REPORTED_OFFENSIVE: number;
                            readonly COMMENT_REPORTED_ABUSIVE: number;
                            readonly COMMENT_REPORTED_BIO: number;
                            readonly COMMENT_DETECTED_TOXIC: number;
                            readonly COMMENT_DETECTED_SUSPECT_WORD: number;
                            readonly COMMENT_DETECTED_SPAM: number;
                            readonly COMMENT_DETECTED_REPEAT_POST: number;
                            readonly COMMENT_DETECTED_RECENT_HISTORY: number;
                            readonly COMMENT_DETECTED_NEW_COMMENTER: number;
                            readonly COMMENT_DETECTED_LINKS: number;
                            readonly COMMENT_DETECTED_BANNED_WORD: number;
                        };
                    };
                };
                readonly metadata: {
                    readonly wordList: {
                        readonly timedOut: boolean | null;
                    } | null;
                };
            } | null;
            readonly viewerDidModerate: boolean | null;
            readonly statusHistory: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly status: COMMENT_STATUS;
                        readonly moderator: {
                            readonly id: string;
                            readonly username: string | null;
                        } | null;
                    };
                }>;
            };
            readonly " $fragmentRefs": FragmentRefs<"ModerateCardDetailsContainer_comment">;
        };
    };
};
export type SingleModerateSubscription = {
    readonly response: SingleModerateSubscriptionResponse;
    readonly variables: SingleModerateSubscriptionVariables;
};



/*
subscription SingleModerateSubscription(
  $commentID: ID!
) {
  commentStatusUpdated(id: $commentID) {
    comment {
      id
      status
      ...ModerateCardDetailsContainer_comment
      revision {
        actionCounts {
          flag {
            reasons {
              COMMENT_REPORTED_SPAM
              COMMENT_REPORTED_OTHER
              COMMENT_REPORTED_OFFENSIVE
              COMMENT_REPORTED_ABUSIVE
              COMMENT_REPORTED_BIO
              COMMENT_DETECTED_TOXIC
              COMMENT_DETECTED_SUSPECT_WORD
              COMMENT_DETECTED_SPAM
              COMMENT_DETECTED_REPEAT_POST
              COMMENT_DETECTED_RECENT_HISTORY
              COMMENT_DETECTED_NEW_COMMENTER
              COMMENT_DETECTED_LINKS
              COMMENT_DETECTED_BANNED_WORD
            }
          }
        }
        metadata {
          wordList {
            timedOut
          }
        }
        id
      }
      statusHistory(first: 1) {
        edges {
          node {
            status
            moderator {
              id
              username
            }
            id
          }
        }
      }
    }
  }
}

fragment CommentRevisionContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    id
  }
  revisionHistory {
    id
    body
    createdAt
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
  }
}

fragment FlagDetailsContainer_comment on Comment {
  flags {
    nodes {
      flagger {
        username
        id
      }
      reason
      additionalDetails
    }
  }
  revision {
    metadata {
      perspective {
        score
      }
    }
    id
  }
}

fragment LinkDetailsContainer_comment on Comment {
  id
  story {
    id
    url
  }
}

fragment ModerateCardDetailsContainer_comment on Comment {
  editing {
    edited
  }
  revision {
    actionCounts {
      flag {
        reasons {
          COMMENT_REPORTED_OFFENSIVE
          COMMENT_REPORTED_ABUSIVE
          COMMENT_REPORTED_SPAM
          COMMENT_REPORTED_OTHER
        }
      }
    }
    metadata {
      perspective {
        score
      }
    }
    id
  }
  ...FlagDetailsContainer_comment
  ...CommentRevisionContainer_comment
  ...LinkDetailsContainer_comment
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "commentID"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "commentID"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_SPAM",
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_OTHER",
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_OFFENSIVE",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_ABUSIVE",
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_REPORTED_BIO",
        "storageKey": null
    } as any), v9 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_TOXIC",
        "storageKey": null
    } as any), v10 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_SUSPECT_WORD",
        "storageKey": null
    } as any), v11 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_SPAM",
        "storageKey": null
    } as any), v12 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_REPEAT_POST",
        "storageKey": null
    } as any), v13 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_RECENT_HISTORY",
        "storageKey": null
    } as any), v14 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_NEW_COMMENTER",
        "storageKey": null
    } as any), v15 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_LINKS",
        "storageKey": null
    } as any), v16 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "COMMENT_DETECTED_BANNED_WORD",
        "storageKey": null
    } as any), v17 = ({
        "alias": null,
        "args": null,
        "concreteType": "CommentRevisionWordListMetadata",
        "kind": "LinkedField",
        "name": "wordList",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timedOut",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v18 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 1
        } as any)
    ], v19 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v20 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "moderator",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            (v19 /*: any*/)
        ],
        "storageKey": null
    } as any), v21 = ({
        "kind": "ClientExtension",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerDidModerate",
                "storageKey": null
            }
        ]
    } as any), v22 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v23 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any), v24 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any), v25 = [
        (v22 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SingleModerateSubscription",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CommentStatusUpdatedPayload",
                    "kind": "LinkedField",
                    "name": "commentStatusUpdated",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "comment",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentRevision",
                                    "kind": "LinkedField",
                                    "name": "revision",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ActionCounts",
                                            "kind": "LinkedField",
                                            "name": "actionCounts",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "FlagActionCounts",
                                                    "kind": "LinkedField",
                                                    "name": "flag",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "FlagReasonActionCounts",
                                                            "kind": "LinkedField",
                                                            "name": "reasons",
                                                            "plural": false,
                                                            "selections": [
                                                                (v4 /*: any*/),
                                                                (v5 /*: any*/),
                                                                (v6 /*: any*/),
                                                                (v7 /*: any*/),
                                                                (v8 /*: any*/),
                                                                (v9 /*: any*/),
                                                                (v10 /*: any*/),
                                                                (v11 /*: any*/),
                                                                (v12 /*: any*/),
                                                                (v13 /*: any*/),
                                                                (v14 /*: any*/),
                                                                (v15 /*: any*/),
                                                                (v16 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentRevisionMetadata",
                                            "kind": "LinkedField",
                                            "name": "metadata",
                                            "plural": false,
                                            "selections": [
                                                (v17 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v18 /*: any*/),
                                    "concreteType": "CommentModerationActionConnection",
                                    "kind": "LinkedField",
                                    "name": "statusHistory",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentModerationActionEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentModerationAction",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v20 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "statusHistory(first:1)"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ModerateCardDetailsContainer_comment"
                                },
                                (v21 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Subscription",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SingleModerateSubscription",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CommentStatusUpdatedPayload",
                    "kind": "LinkedField",
                    "name": "commentStatusUpdated",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "comment",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EditInfo",
                                    "kind": "LinkedField",
                                    "name": "editing",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "edited",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentRevision",
                                    "kind": "LinkedField",
                                    "name": "revision",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ActionCounts",
                                            "kind": "LinkedField",
                                            "name": "actionCounts",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "FlagActionCounts",
                                                    "kind": "LinkedField",
                                                    "name": "flag",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "FlagReasonActionCounts",
                                                            "kind": "LinkedField",
                                                            "name": "reasons",
                                                            "plural": false,
                                                            "selections": [
                                                                (v6 /*: any*/),
                                                                (v7 /*: any*/),
                                                                (v4 /*: any*/),
                                                                (v5 /*: any*/),
                                                                (v8 /*: any*/),
                                                                (v9 /*: any*/),
                                                                (v10 /*: any*/),
                                                                (v11 /*: any*/),
                                                                (v12 /*: any*/),
                                                                (v13 /*: any*/),
                                                                (v14 /*: any*/),
                                                                (v15 /*: any*/),
                                                                (v16 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentRevisionMetadata",
                                            "kind": "LinkedField",
                                            "name": "metadata",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevisionPerspectiveMetadata",
                                                    "kind": "LinkedField",
                                                    "name": "perspective",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "score",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v17 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FlagsConnection",
                                    "kind": "LinkedField",
                                    "name": "flags",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Flag",
                                            "kind": "LinkedField",
                                            "name": "nodes",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "flagger",
                                                    "plural": false,
                                                    "selections": [
                                                        (v19 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "reason",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "additionalDetails",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "site",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentRevision",
                                    "kind": "LinkedField",
                                    "name": "revisionHistory",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "body",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "media",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v22 /*: any*/),
                                                        (v23 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "width",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "height",
                                                            "storageKey": null
                                                        },
                                                        (v24 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "video",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "GiphyMedia",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": (v25 /*: any*/),
                                                    "type": "TwitterMedia",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v22 /*: any*/),
                                                        (v24 /*: any*/),
                                                        (v23 /*: any*/)
                                                    ],
                                                    "type": "YouTubeMedia",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": (v25 /*: any*/),
                                                    "type": "ExternalMedia",
                                                    "abstractKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Story",
                                    "kind": "LinkedField",
                                    "name": "story",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v22 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v18 /*: any*/),
                                    "concreteType": "CommentModerationActionConnection",
                                    "kind": "LinkedField",
                                    "name": "statusHistory",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentModerationActionEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentModerationAction",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v20 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "statusHistory(first:1)"
                                },
                                (v21 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "26f4c0f758829a7022bf7c26ba3caf2d",
            "metadata": {},
            "name": "SingleModerateSubscription",
            "operationKind": "subscription",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'e5e984549b07269fe0d8686b74c071df';
export default node;
