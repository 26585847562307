/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModerateCardDetailsContainer_comment = {
    readonly editing: {
        readonly edited: boolean;
    };
    readonly revision: {
        readonly actionCounts: {
            readonly flag: {
                readonly reasons: {
                    readonly COMMENT_REPORTED_OFFENSIVE: number;
                    readonly COMMENT_REPORTED_ABUSIVE: number;
                    readonly COMMENT_REPORTED_SPAM: number;
                    readonly COMMENT_REPORTED_OTHER: number;
                };
            };
        };
        readonly metadata: {
            readonly perspective: {
                readonly score: number;
            } | null;
        };
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"FlagDetailsContainer_comment" | "CommentRevisionContainer_comment" | "LinkDetailsContainer_comment">;
    readonly " $refType": "ModerateCardDetailsContainer_comment";
};
export type ModerateCardDetailsContainer_comment$data = ModerateCardDetailsContainer_comment;
export type ModerateCardDetailsContainer_comment$key = {
    readonly " $data"?: ModerateCardDetailsContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardDetailsContainer_comment">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModerateCardDetailsContainer_comment",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "EditInfo",
            "kind": "LinkedField",
            "name": "editing",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "edited",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "CommentRevision",
            "kind": "LinkedField",
            "name": "revision",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActionCounts",
                    "kind": "LinkedField",
                    "name": "actionCounts",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "FlagActionCounts",
                            "kind": "LinkedField",
                            "name": "flag",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FlagReasonActionCounts",
                                    "kind": "LinkedField",
                                    "name": "reasons",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_REPORTED_OFFENSIVE",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_REPORTED_ABUSIVE",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_REPORTED_SPAM",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "COMMENT_REPORTED_OTHER",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentRevisionMetadata",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CommentRevisionPerspectiveMetadata",
                            "kind": "LinkedField",
                            "name": "perspective",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "score",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FlagDetailsContainer_comment"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CommentRevisionContainer_comment"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LinkDetailsContainer_comment"
        }
    ],
    "type": "Comment",
    "abstractKey": null
} as any);
(node as any).hash = 'e17e02fdc0a94c1c4c1e6512f52a475f';
export default node;
